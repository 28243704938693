import { Debouncer, noop } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../../core';
import type * as Prebid from '../../../prebid';
import { Api } from '../Api';
import type { Config } from '../Config';
import type { Context } from '../Context';
import { LazyLoad } from './LazyLoad';
import { Ppid } from './Ppid';
import { SizeMapping } from './SizeMapping';
import { Tracking } from './Tracking';
import { Slot } from './Slot';

export class Active extends Core.Service.Generic.State.Active<Active.Options> {
	static readonly from = (options: Active.Options) => new this(options);

	readonly activate: Context = this.#activate.bind(this);

	readonly #debouncer = new Debouncer<string>(16, this.abortSignal);
	readonly #queue: Active.Queue = new Array<string>();
	readonly #tracking = new Tracking(this.options);
	readonly #ppid = new Ppid(this.options);
	readonly #lazyLoad = new LazyLoad(this.options);
	readonly #sizeMapping = new SizeMapping(this.options);

	protected override async executeSetup(abortSignal?: AbortSignal) {
		await this.#tracking.setup(abortSignal);
		await this.#ppid.setup(abortSignal);
		await this.#lazyLoad.setup(abortSignal);
		await this.#sizeMapping.setup(abortSignal);

		const { targeting } = this.options;

		await Api.execute(
			function () {
				const keyValues = Object.entries({
					...targeting,
					...window.DisplayAdsUtilities?.customTargeting,
				});

				for (const [key, values] of keyValues) this.pubads().setTargeting(key, values);

				this.pubads().disableInitialLoad();
				this.pubads().enableSingleRequest();
				this.enableServices();
			},
			this.abortSignal,
			abortSignal,
		);

		abortSignal?.throwIfAborted();
		await this.#tryRequestAds(this.#processQueue(abortSignal), abortSignal);
		this.#tryRunActivateLoop().catch(noop);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await this.#tracking.destroy(abortSignal);
		await this.#ppid.destroy(abortSignal);
		await this.#lazyLoad.destroy(abortSignal);
		await this.#sizeMapping.destroy(abortSignal);

		const { prebid, slots } = this.options;

		try {
			await prebid?.cleanupAfterPageView(abortSignal);
		} catch (error) {
			Core.log.error('ADMANAGER', 'Prebid', 'Cleanup', 'Failed cleanup after page view', { error });
		}

		await Api.execute(function () {
			this.destroySlots();
			this.pubads().clearTargeting();
		}, abortSignal);

		/**
		 * For some reason, using `googletag.destroySlots()` doesn't seem to clean up all slots
		 * properly. From the looks of it only unfilled ad slots are affected, but unfortunately it
		 * results in those ad slots being unable to be filled with ads later on. As a workaround,
		 * we'll attempt to do the cleanup ourselves.
		 */
		const slotsQuerySelector = slots.map(({ id }) => `#${id}`).join(', ');
		const slotElements = window.document.querySelectorAll(slotsQuerySelector).values();

		for (const slotElement of slotElements) {
			if (!(slotElement instanceof HTMLElement)) continue;

			try {
				// Remove attribute left behind by googletag:
				slotElement.removeAttribute('data-google-query-id');

				// Remove child nodes left behind by googletag:
				slotElement.replaceChildren();

				// Remove CSS properties left behind by googletag:
				slotElement.style.removeProperty('display');
			} catch {
				// Do nothing, try the next element...
			}
		}
	}

	#activate(id: string) {
		if (this.isDestroyed()) return;

		if (this.isSetUp()) this.#debouncer.add(id);
		else this.#queue.push(id);

		return async () => await this.#deactivate(id);
	}

	async #deactivate(id: string) {
		if (this.isDestroyed()) return false;

		return await Api.execute(function () {
			for (const slot of this.pubads().getSlots()) {
				if (slot.getSlotElementId() === id) return this.destroySlots([slot]);
			}

			return false;
		});
	}

	async #tryRunActivateLoop() {
		try {
			if (this.isDestroyed()) return;

			for await (const idsChunk of this.#debouncer) {
				if (this.isDestroyed()) return;

				this.#queue.push(...idsChunk);
				await this.#tryRequestAds(this.#processQueue());
			}
		} catch (error) {
			Core.log.error('ADMANAGER', 'Slots', 'Activate', 'Failed requesting ads for queued slots', {
				error,
			});
		}
	}

	async *#processQueue(abortSignal?: AbortSignal) {
		const abortSignals = [this.abortSignal, abortSignal] as const;
		for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

		const { options } = this;

		while (true) {
			const slotId = this.#queue.shift();
			if (!slotId) break;

			const slot = await Slot.from(this.options, slotId, this.#sizeMapping, ...abortSignals);

			if (!slot) {
				Core.log.error('ADMANAGER', 'Slots', 'Config', 'Slot not found', { slotId, options });
				continue;
			}

			yield slot;
		}
	}

	async #tryRequestAds(slots: AsyncIterable<googletag.Slot>, abortSignal?: AbortSignal) {
		try {
			const abortSignals = [this.abortSignal, abortSignal] as const;
			for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

			const uniqueSlots = new Set<googletag.Slot>();
			for await (const slot of slots) uniqueSlots.add(slot);

			if (!uniqueSlots.size) {
				Core.log.warn(
					'REQUEST',
					ShoAd.AdType.Display,
					'Slots',
					'Aborted due to no slots registered',
				);

				return;
			}

			await Api.execute(
				function () {
					for (const slot of uniqueSlots) this.display(slot);
				},
				...abortSignals,
			);

			await this.#tryRunPrebidAuction(uniqueSlots, abortSignal);

			await Api.execute(
				function () {
					Core.log('REQUEST', ShoAd.AdType.Display, 'Slots', 'Initiated', { uniqueSlots });
					this.pubads().refresh(Array.from(uniqueSlots));
				},
				...abortSignals,
			);
		} catch (error) {
			Core.log.error('REQUEST', ShoAd.AdType.Display, 'Slots', 'Failed requesting ads for slots', {
				slots,
				error,
			});
		}
	}

	async #tryRunPrebidAuction(slots: ReadonlySet<googletag.Slot>, abortSignal?: AbortSignal) {
		try {
			const abortSignals = [this.abortSignal, abortSignal] as const;
			for (const abortSignal of abortSignals) abortSignal?.throwIfAborted();

			const { options } = this;
			const { prebid } = options;

			if (!prebid) {
				Core.log.warn(
					'ADMANAGER',
					'Prebid',
					'Auction',
					'Prebid not configured, skipping auction...',
					{ options },
				);

				return;
			}

			const allowedDivIds = Array.from(slots).map(slot => slot.getSlotElementId());
			const auction = prebid.runAuction(allowedDivIds, abortSignal);

			for await (const [type] of auction) {
				// When Relevant Yield wants to refresh ads, we break the loop:
				if (type === 'refresh') break;
			}
		} catch (error) {
			Core.log.error(
				'ADMANAGER',
				'Prebid',
				'Auction',
				'An error occurred! Attempting to continue...',
				{ error },
			);
		}
	}
}

export namespace Active {
	export type Options = LazyLoad.Options &
		Ppid.Options &
		SizeMapping.Options &
		Tracking.Options &
		Config &
		Readonly<{ prebid?: Prebid.Service.Context }>;

	export type Queue = Array<string>;
}

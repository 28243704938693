import './index.css';
import React from 'react';
import * as Core from '../../../core';
import * as _Styles from '../../Styles';
import * as Position from '../../Position';
import { Styles } from '../Styles';
import type { Config } from '../Config';
import * as namespace from '../.namespace';

/** Layout for displaying ads on desktop. */
export function Wrapper({ breakpoints, maxContentWidth, children }: Wrapper.Props) {
	return (
		<>
			<Position.Audience />

			<div className={_Styles.topscrollElementClass}>
				<Position.Topscroll />
			</div>

			<div className={_Styles.wrapperElementClass}>
				<div className={_Styles.wallpaperElementClass}>
					<Position.Wallpaper />
				</div>

				<div className={_Styles.topElementClass}>
					<Position.Top />
				</div>

				<div className={_Styles.pageElementClass}>
					<div className={_Styles.skyscrapersElementClass}>
						<Position.Skyscraper name={Core.AdPosition.SkyscraperLeftTop} />
						<Position.Skyscraper name={Core.AdPosition.SkyscraperLeftBottom} />
					</div>

					<div className={_Styles.contentElementClass}>{children}</div>

					<div className={_Styles.skyscrapersElementClass}>
						<Position.Skyscraper name={Core.AdPosition.SkyscraperRightTop} />
						<Position.Skyscraper name={Core.AdPosition.SkyscraperRightBottom} />
					</div>
				</div>
			</div>

			<Styles breakpoints={breakpoints} maxContentWidth={maxContentWidth} />
		</>
	);
}

export namespace Wrapper {
	export const displayName = namespace.Layout.nameof({ Wrapper });

	export type Props = React.PropsWithChildren<Config>;
}

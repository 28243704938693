import type { Nullable } from '@smd/utilities';
import * as Core from '../../../core';
import { Api } from '../Api';
import type { Config } from '../Config';
import type { Active } from './Active';
import type { SizeMapping } from './SizeMapping';

export namespace Slot {
	export namespace Default {
		export async function from(
			fallBackPath: string,
			{ collapseEmptyDiv, id, sizes, sizeMappingName, targeting, path = fallBackPath }: Config.Slot,
			sizeMapping: SizeMapping,
			...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
		) {
			return await Api.execute(
				function (): googletag.Slot | null {
					try {
						const slot = this.defineSlot(path, sizes, id) as ReturnType<
							typeof this.defineSlot
						> | null;

						if (!slot) throw new Error('googletag.defineSlot returned null');

						if (sizeMappingName) {
							const sizeMappingArray = sizeMapping.get(sizeMappingName);
							if (sizeMappingArray) slot.defineSizeMapping(sizeMappingArray);
						}

						if (collapseEmptyDiv) {
							const [collapseUnfilled, collapseBeforeAdFetch] = collapseEmptyDiv;
							slot.setCollapseEmptyDiv(collapseUnfilled, collapseBeforeAdFetch);
						}

						for (const [key, values] of Object.entries(targeting)) slot.setTargeting(key, values);

						slot.addService(this.pubads());

						return slot;
					} catch (error) {
						Core.log.error('ADMANAGER', 'Slots', 'Config', 'Failed defining slot', {
							path,
							sizes,
							id,
							error,
						});

						return null;
					}
				},
				...abortSignals,
			);
		}
	}

	export namespace OutOfPage {
		export async function from(
			fallBackPath: string,
			{ id, path = fallBackPath }: Config.OutOfPageSlot,
			...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
		) {
			return await Api.execute(
				function (): googletag.Slot | null {
					try {
						const slot = this.defineOutOfPageSlot(path, id) as ReturnType<
							typeof this.defineOutOfPageSlot
						> | null;

						if (!slot) throw new Error('googletag.defineOutOfPageSlot returned null');

						slot.addService(this.pubads());

						return slot;
					} catch (error) {
						Core.log.error('ADMANAGER', 'Slots', 'Config', 'Failed defining out of page slot', {
							path,
							id,
							error,
						});

						return null;
					}
				},
				...abortSignals,
			);
		}
	}

	export async function from(
		{ slots, outOfPageSlots, path }: Active.Options,
		slotId: string,
		sizeMapping: SizeMapping,
		...abortSignals: ReadonlyArray<Nullable<AbortSignal>>
	) {
		const config = slots.find(slot => slot.id === slotId);

		if (config) {
			return await Default.from(path, config, sizeMapping, ...abortSignals);
		}

		const outOfPageConfig = outOfPageSlots?.find(slot => slot.id === slotId);

		if (outOfPageConfig) {
			return await OutOfPage.from(path, outOfPageConfig, ...abortSignals);
		}

		return null;
	}
}
